// -----------------------------------------------------------------------------------------------------
@use '~@angular/material' as mat;
// @ Custom color maps for Fuse
// -----------------------------------------------------------------------------------------------------
$white: (
    500: white,
    contrast: (
        500: $dark-primary-text,
    ),
);

$black: (
    500: black,
    contrast: (
        500: $light-primary-text,
    ),
);

$navy: (
    50: #ececee,
    100: #c5c6cb,
    200: #9ea1a9,
    300: #7d818c,
    400: #5c616f,
    500: #3c4252,
    600: #353a48,
    700: #2d323e,
    800: #262933,
    900: #1e2129,
    A100: #c5c6cb,
    A200: #9ea1a9,
    A400: #5c616f,
    A700: #2d323e,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
        A100: $dark-primary-text,
        A200: $light-primary-text,
        A400: $light-primary-text,
        A700: $light-primary-text,
    ),
);

// Generate Fuse color classes for custom palettes
$custom_palettes: (
    "white": $white,
    "black": $black,
    "navy": $navy,
);

@include fuse-color-classes($custom_palettes);

// -----------------------------------------------------------------------------------------------------
// @ Typography
// -----------------------------------------------------------------------------------------------------

// Angular Material typography
$typography: mat.define-typography-config(
    $font-family: "Muli, Helvetica Neue, Arial, sans-serif",
    $title: mat.define-typography-level(20px, 32px, 600),
    $body-2: mat.define-typography-level(14px, 24px, 600),
    $button: mat.define-typography-level(14px, 14px, 600),
    $input: mat.define-typography-level(16px, 1.125, 400)
        // line-height must be unitless !!!,,
);

// Setup the typography
@include mat.all-component-typographies($typography);

// -----------------------------------------------------------------------------------------------------
// @ Component theming
// -----------------------------------------------------------------------------------------------------

// Import app and page component themes here to enable theming for theme
@import "src/app/themes/form-control.theme";
@import "src/app/components/navbar/navbar.theme";
@import "src/app/components/footer/footer.theme";

// Define a mixin for easier access
@mixin app-theme($theme) {
    // Layout components
    @include form-control-theme($theme);
    @include navbar-theme($theme);
    @include footer-theme($theme);
}

// -----------------------------------------------------------------------------------------------------
// @ Define the default theme
// -----------------------------------------------------------------------------------------------------

// Define the primary, accent and warn palettes
$default-primary-palette: mat.define-palette($navy);
$default-accent-palette: mat.define-palette(
    mat.$light-blue-palette,
    500,
    400,
    700
);
$default-warn-palette: mat.define-palette(mat.$red-palette);

// Create the Material theme object
$theme: mat.define-light-theme(
    $default-primary-palette,
    $default-accent-palette,
    $default-warn-palette
);

// Add ".theme-default" class to the body to activate this theme.
// Class name must start with "theme-" !!!
body.theme-default {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($theme);

    // Apply the theme to the Fuse Core
    @include fuse-core-theme($theme);

    // Apply the theme to the user components
    @include app-theme($theme);

    // Generate Fuse color classes for primary, accent and warn colors
    $palettes: (
        primary: $default-primary-palette,
        accent: $default-accent-palette,
        warn: $default-warn-palette,
    );

    @include fuse-color-classes($palettes);

    font-family: Muli, sans-serif !important;
    color: #313943 !important;
    font-weight: 500;
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: auto !important;

    h1 {
        font-family: Muli, sans-serif !important;
        font-size: 32px;
        font-weight: bold;
    }

    h2 {
        font-family: Muli, sans-serif !important;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
    }

    h3 {
        font-family: Muli, sans-serif !important;
        font-size: 18px;
        font-weight: bold;
    }

    h4 {
        font-family: Muli, sans-serif !important;
        font-size: 14px;
        font-weight: bold;
    }

    h5 {
        font-family: Muli, sans-serif !important;
        font-size: 13px;
        font-weight: bold;
    }

    p,
    a {
        font-family: Muli, sans-serif !important;
        font-weight: 500;
        font-style: normal;
    }

    mat-label {
        font-family: Muli, sans-serif !important;
        font-weight: 600 !important;
        color: mat.get-color-from-palette($default-primary-palette);
    }

    .mat-flat-button {
        border-radius: 12px;
        padding: 4px 14px;
    }

    .emphasis {
        color: mat.get-color-from-palette($default-accent-palette, 500);
    }

    .emphasis-2 {
        color: #62bb46;
    }
}
