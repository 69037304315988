@use '~@angular/material' as mat;
@mixin form-control-theme($theme) {
    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);

    mat-form-field {
        width: 100%;
        padding-bottom: 24px;

        &.mat-form-field-invalid {
            .mat-form-field-flex {
                border: solid 2px mat.get-color-from-palette($warn);
            }
        }

        &.mat-form-field:not(.mat-form-field-invalid) .mat-form-field-label {
            color: rgba(mat.get-color-from-palette($primary), 0.8);
        }

        &.mat-form-field .mat-form-field-label {
            font-weight: 600;
            height: 100%;
        }

        &.mat-form-field:not(.mat-form-field-should-float)
            .mat-form-field-label {
            margin-top: 7px;
            margin-left: 16px;
        }

        .mat-form-field-prefix
            + .mat-form-field-infix
            .mat-form-field-label-wrapper {
            margin-top: 0;
            margin-left: -46px;
        }

        .mat-form-field-flex {
            align-items: center !important;
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
            background-color: white;
            border: solid 1.5px transparent;
            border-radius: 12px;
        }

        .mat-select-trigger {
            height: 100%;
            padding-bottom: 8px;
        }

        .mat-select-arrow-wrapper {
            font-size: 26px;
            margin-right: 30px;
            margin-bottom: 15px;

            .mat-select-arrow {
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;

                border: none;
                margin: 0;

                &:before {
                    content: "\f107";
                }
            }
        }

        .mat-input-element,
        .mat-select,
        .stripe-element {
            &:not(textarea) {
                height: 40px;
            }

            font-size: 20px;
            width: 100%;
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 4px;
            font-weight: 300;

            border: 1.5px solid transparent;
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;

            color: mat.get-color-from-palette($default-primary-palette);

            &::placeholder {
                color: #b3b3b3;
            }

            .mat-select-placeholder,
            .mat-select-value-text {
                margin-top: 8px;
                padding-top: 8px;
                padding-bottom: 8px;

                color: mat.get-color-from-palette($default-primary-palette);
                opacity: 1;
            }
        }

        .mat-form-field-prefix {
            padding-left: 16px;
            padding-right: 4px;
        }

        .mat-form-field-infix {
            padding-bottom: 0;
            border: none;
            padding-top: 0;
        }

        &.mat-focused {
            .mat-form-field-required-marker {
                color: mat.get-color-from-palette($default-primary-palette);
            }
        }

        &.mat-form-field-should-float {
            .mat-form-field-label-wrapper {
                top: -22px;
            }
        }

        .mat-form-field-label-wrapper {
            top: -15px;

            label {
                color: mat.get-color-from-palette($default-primary-palette);
            }

            .mat-form-field-label {
                height: 20px;
            }
        }

        /* Change autocomplete styles in WebKit */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus {
            border: 1px solid transparent;
            -webkit-text-fill-color: #000;
            -webkit-box-shadow: 0 0 0 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    .mat-select-panel-wrap {
        .mat-select-panel {
            border-radius: 12px;
        }

        mat-option:not(:last-child) {
            border-bottom: solid 1px
                rgba(mat.get-color-from-palette($primary), 0.5);
        }
    }

    .mat-select-panel {
        mat-option {
            height: 50px;
            padding-left: 0;
            padding-right: 0;
            line-height: 48px !important;

            .mat-option-text {
                font-size: 18px !important;
                text-align: center;
                margin-top: auto;
                margin-bottom: auto;
            }
        }
    }
    .mat-select-value {
        max-width: 100% !important;
        font-size: 18px;
        color: #5c5c5c !important;
        font-weight: bolder;
        padding-right: 16px;
    }

    .parameters,
    ngx-dropzone {
        .mat-form-field-flex {
            box-shadow: none !important;
        }
    }
}
