@use '~@angular/material' as mat;
@mixin footer-theme($theme) {
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    nerri-footer {
        background: #f5f5f5;

        .navbar-item {
            color: #1e2630;

            &.active {
                color: mat.get-color-from-palette($accent);
                font-weight: 700;
            }

            &:hover,
            &:focus {
                color: mat.get-color-from-palette($accent);
            }
        }

        .copyright a {
            color: #1e2630;
            font-size: 14px;
        }

        .footer-social-link {
            color: #1e2630;

            &:hover {
                color: mat.get-color-from-palette($accent);
            }
        }
    }
}
